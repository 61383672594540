/*
 * Name        : DFA Sub-menuToggles
 * Version     : 0.1.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Sub-menu Toggles
*/


const addNavSubmenuToggles = () => {
	const submenuLinks  = document.querySelectorAll('.menu-item-has-children');

	Array.from(submenuLinks).forEach((submenuLink) => {
		const toggleSwitch = document.createElement('span');
		const submenu = submenuLink.querySelector('.sub-menu');

		toggleSwitch.classList.add('menu-item-has-children-toggle');
		submenuLink.insertBefore(toggleSwitch, submenu);

		const toggle = submenuLink.querySelector('.menu-item-has-children-toggle');
		const parentContainer =  toggle.parentNode;

		toggle.addEventListener('click', () => {
			if (parentContainer.classList.contains('is-active')) {
				parentContainer.classList.remove('is-active');
			} else {
				parentContainer.classList.add('is-active');
			}
		});
	});
};

export default function () {
	addNavSubmenuToggles();
}
