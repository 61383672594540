/*
 * Name        : Popup
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfried.com
 * Description : Adds local cookie and manages popup visibility
*/

export default () => {
	const html          = document.querySelector('html');
	const popup         = document.querySelector('.PopupModal');
	const closePopup    = document.querySelector('.PopupModal__close');
	const cookieID      = (popup) ? popup.dataset.cookie : '';
	const popupNag      = document.querySelector('#df-popup-nag');

	function setPopupCookie() {
		if (!popupNag.checked) {
			return;
		}
		localStorage.setItem('popup', cookieID);
	}

	function hidePopup() {
		html.classList.remove('PopupModal--is-visible');
	}

	// Check browser support
	if ('undefined' !== typeof (Storage)) {
		if (cookieID === localStorage.getItem('popup')) {
			html.classList.remove('PopupModal--is-visible');
		} else {
			html.classList.add('PopupModal--is-visible');
		}

		if (popup) {
			closePopup.addEventListener('click', hidePopup);
			closePopup.addEventListener('click', setPopupCookie);
		}
	}
};
