/*
 * Name        : DFA Back to Top
 * Version     : 0.1.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Back to Top
*/

import addMultipleEventListener from './_addMultipleEventListener';


function initShowBackToTop(button) {
	const buttonHeight = button.offsetHeight;
	const footer       = document.querySelector('.Footer');
	const footerTop    = footer.offsetTop;
	const winHeight    = window.innerHeight;
	const winScrollPos = window.pageYOffset;

	if (winScrollPos > winHeight) {
		button.classList.add('is-active');
	} else {
		button.classList.remove('is-active');
	}

	if ((winScrollPos + winHeight) >= (footerTop + buttonHeight)) {
		button.classList.add('is-sticky');
	} else {
		button.classList.remove('is-sticky');
	}
}

export default function () {
	const backToTop        = document.querySelector('.js-back-to-top');
	const eListenerOptions = ['load', 'scroll', 'resize', 'orientationchange'];

	if (backToTop) {
		addMultipleEventListener(window, eListenerOptions, () => {
			initShowBackToTop(backToTop);
		});
	}
}
