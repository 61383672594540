// import { fetch as fetchPolyfill } from 'whatwg-fetch';
// import WebFontConfig from './_web_fonts';

// import chooseRandom from './deep-fried-utilities/_chooseRandom';
// import elemOnScreen from './deep-fried-utilities/_elemOnScreen';
// import finalEvent from './deep-fried-utilities/_finalEvent';
import './deep-fried-utilities/_polyfills';
// import setupRetinaAssets from './deep-fried-utilities/_retina';

// import setupACFGoogleMaps from './deep-fried-modules/_acf_google_maps';
import setupBackToTop from './deep-fried-modules/_back_to_top';
import setupShowBackToTop from './_showBackToTop';
import setupNav from './deep-fried-modules/_nav';
import setupNavSubmenuToggles from './_addNavSubMenuToggles';
import setupNavOverlay from './_navOverlay';
import setupStickySidebar from './_stickySidebar';
import setupTagalong from './_tagalong';

import setupSlider from './components/_slider';
import setupPopup from './components/_popup';

jQuery(document).ready(() => {
	setupNav();
	setupNavSubmenuToggles();
	setupNavOverlay();
	setupStickySidebar();
	setupTagalong();
	setupBackToTop();
	setupShowBackToTop();
	// setupRetinaAssets();
	// setupACFGoogleMaps();
	setupSlider();
	setupPopup();
});

// window.WebFontConfig = WebFontConfig; // Expose as global variable for webfont loader JS
