/*
 * Name        : DFA Back to Top Module
 * Version     : 0.6.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Adds a click event listener to `.back-to-top`
*/

import { $htmlAndBody } from '../_var';

const backToTop = document.querySelectorAll('.js-back-to-top');

// For custom speed, adjust timing parameter
export default (timing) => {
	const time = 'undefined' !== typeof timing ? timing : 250;

	/* eslint-disable func-names, prefer-arrow-callback */
	Array.from(backToTop)
		.forEach((btt) => {
			btt.addEventListener('click', function (event) {
				event.preventDefault();
				$htmlAndBody.animate({ scrollTop: 0 }, time);
			});
		});
};
