/*
 * Name        : DFA Sliders
 * Version     : 0.3.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Sliders
*/

import { $ } from '../_var';

export default () => {
	const $Slider = $('.Slider__li');

	if (0 < $Slider.length) {
		$Slider.bxSlider({
			controls: true,
			pager: false,
			touchEnabled: false,
			auto: true,
			mode: 'fade',
			speed: 900,
			adaptiveHeight: true,
			nextText: '<i class="fal fa-caret-right"></i>',
			prevText: '<i class="fal fa-caret-left"></i>',
		});
	}

	$('.ProjectList').bxSlider({
		controls: true,
		mode: 'fade',
		pager: false,
		nextText: '<i class="fal fa-long-arrow-alt-right"></i>',
		prevText: '<i class="fal fa-long-arrow-alt-left"></i>',
	});
};
