/*
 * Name        : DFA Nav Overlay
 * Version     : 0.1.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Nav Overlay
*/

import { $ } from './_var';

export default function () {
	const navToggle  = $('.NavButton--close');
	const navOverlay = $('.Nav__overlay');

	navOverlay.on('click', () => {
		navToggle.click();
	});
}
