/*
 * Name        : DFA Stick Sidebar
 * Version     : 0.1.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Stick Sidebar
*/

import { $, $window, $html } from './_var';

function stickyOnMobile() {
	const currentPos       = window.pageYOffset;
	const html             = document.querySelector('html');
	const sidebar          = document.querySelector('.Sidebar');
	const sidebarLayout    = document.querySelector('.Wrap--has-sidebar');
	const sidebarLayoutCSS = ( sidebarLayout ) ? window.getComputedStyle(sidebarLayout): ''; // Get Sidebar style properties
	const sidebarTop       = ( sidebar ) ? sidebar.parentNode.offsetTop: ''; // Top of sidebar content area

	if (sidebar) {
		// Sticky Sidebar (mobile)
		if ('flex' !== sidebarLayoutCSS.getPropertyValue('display')) {
			if (sidebarTop <= currentPos && 0 <= (currentPos - sidebarTop)) {
				html.classList.add('Tagalong--is-sticky');
			} else {
				html.classList.remove('Tagalong--is-sticky');
			}
		}
	}
}

function stickyOnDesktop() {
	const $sidebar        = $('.Sidebar');
	const $sidebarContent = $('.Sidebar__content');
	const $sidebarLayout  = $('.Wrap--has-sidebar');
	let windowHeight      = window.innerHeight;
	const $sidebarContentHeight = $sidebarContent.height();
	windowHeight = window.innerHeight;

	if (0 < $sidebar.length && $sidebar.hasClass('Sidebar--sticky')) {
		// Sticky Sidebar (desk)
		if ('flex' === $sidebarLayout.css('display') && $sidebarContentHeight < windowHeight) {
			$sidebarContent.stick_in_parent({ inner_scrolling: true });
		} else {
			$sidebarContent.trigger('sticky_kit:detach');
		}
	}
}

export default function () {
	stickyOnMobile();
	stickyOnDesktop();

	$window.on('resize scroll orientationchange', () => {
		stickyOnMobile();
		stickyOnDesktop();
	});
}
