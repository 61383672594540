/*
 * Name        : DFA Tagalong
 * Version     : 0.1.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Tagalong
*/

import addMultipleEventListener from './_addMultipleEventListener';

function initTagalongSelect() {
	const tagalongSelect = document.querySelector('.TagalongSelect select');

	if (tagalongSelect) {
		tagalongSelect.onchange = (event) => {
			const elem  = document.querySelector(event.target.value);

			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' });
			}
		};
	}
}

function currentProjectGroup() {
	const currentPos       = window.pageYOffset;
	const projectGroup     = document.querySelectorAll('.ProjectGroup');
	const sidebar          = document.querySelector('.Sidebar');
	const sidebarTop       = ( sidebar ) ? sidebar.parentNode.offsetTop: ''; // Top of sidebar content area
	const sidebarTopOffset = 150; // Activates item by this amount before it touches the top of the browser

	if (projectGroup) {
		Array.from(projectGroup).forEach((item, index) => {
			const itemTop = item.offsetTop - 16;
			const itemBottom = itemTop + (item.offsetHeight - 16);

			// Activate or deactivate
			if (currentPos >= (itemTop + (sidebarTop - sidebarTopOffset)) && currentPos <= (itemBottom + (sidebarTop - sidebarTopOffset))) {
				item.classList.add('is-active');
			} else {
				item.classList.remove('is-active');
			}

			// Begin with first item active
			if ( 0 === index && currentPos < (itemTop + (sidebarTop - sidebarTopOffset))) {
				item.classList.add('is-active');
			}
		});
	}
}

function currentTagalongLink() {
	const tagalongLinks = document.querySelectorAll('.Tagalong a');

	if (tagalongLinks) {
		Array.from(tagalongLinks).forEach((link) => {
			const linkUrl = link.getAttribute('href');
			const elem = document.querySelector(linkUrl);

			link.addEventListener('click', (event) => {
				event.preventDefault();
				elem.scrollIntoView({ behavior: 'smooth' });
			});

			if (elem.classList.contains('is-active')) {
				link.parentNode.classList.add('is-active');
			} else {
				link.parentNode.classList.remove('is-active');
			}
		});
	}

}

export default function () {
	const eListenerOptions = ['load', 'scroll', 'resize', 'orientationchange'];

	initTagalongSelect();
	addMultipleEventListener(window, eListenerOptions, currentProjectGroup);
	addMultipleEventListener(window, eListenerOptions, currentTagalongLink);
}
