/*
 * Name        : DFA Multiple Event Listeners
 * Version     : 0.1.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Multiple Event Listeners
*/

export default function addMultipleEventListener(element, events, handler) {
	events.forEach(e => element.addEventListener(e, handler));
}
